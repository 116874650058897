import Bestseller from '../content/Bestseller';
// import ShopCollection from '../content/ShopCollection';
// import NewArrivals from '../content/newArrivals/NewArrivals';

const Shop = () => {
  return (
    <div>
      {/* <NewArrivals products={products} /> */}
      {/* <ShopCollection /> */}
      <Bestseller />
    </div>
  );
};

export default Shop;
