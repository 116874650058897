const Newsletter = () => {
  return (
    <section className="newsletter">
      <div className="newsletter__content">
        <h2 className="newsletter__title headline-4">Join Our Newsletter</h2>
        <p className="newsletter__desc">
          Sign up for deals, new products and promotions
        </p>
        <form action="" className="form-newsletter">
          <img src="./img/icons/email.svg" alt="Email" />
          <input
            type="text"
            className="form-newsletter__input"
            placeholder="Email address"
          />
          <button type="submit" className="form-newsletter__submit">
            Signup
          </button>
        </form>
      </div>
    </section>
  );
};

export default Newsletter;
