const Value = ({ img, title, text }) => {
  return (
    <div className="value">
      <img className="value__img" src={`img/products/values/${img}`} />
      <h4 className="value__title">{title}</h4>
      <p className="value__desc">{text}</p>
    </div>
  );
};

export default Value;
