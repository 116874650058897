import { Link } from 'react-router-dom';
import Logo from '../menu/Logo';
import ListOfMenu from '../menu/ListOfMenu';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__row footer__row-top">
          <div className="footer__title">
            <Logo className={'footer__logo'} />

            <div className="footer__separator"></div>
            <div className="footer__store">
              Выездная заправка автокондизионеров
            </div>
          </div>
          {/* <ul className="footer__nav">
            <li>
              <Link to="#!">Главная</Link>
            </li>
            <li>
              <Link to="#!">Магазин</Link>
            </li>
            <li>
              <Link to="#!">Контакты</Link>
            </li>
          </ul> */}

          <ListOfMenu navList={'footer__nav'} />
        </div>

        <div className="footer__row footer__row--border">
          <div className="footer__copyrights">
            <p>Copyright © 2023 3legant. All rights reserved</p>
            <Link to="#!">Privacy Policy</Link>
            <Link to="#!">Terms of Use</Link>
          </div>

          <div className="footer__socials">
            <Link to="#!">
              <img src="img/products/socials/instagram.svg" alt="Social" />
            </Link>
            <Link to="#!">
              <img src="img/products/socials/facebook.svg" alt="Social" />
            </Link>
            <Link to="#!">
              <img src="img/products/socials/youtube.svg" alt="Social" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
