import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { useContext } from '../../App';
const Order = (props) => {
  const { deleteOrder } = React.useContext(useContext);
  return (
    <div className="item">
      <img src={`./img/products/arrivals/${props.item.img}.jpg`} />
      <h2>{props.item.title}</h2>
      <b>${props.item.price}</b>
      <FaTrash
        className="delete-icon"
        onClick={() => deleteOrder(props.item)}
      />
    </div>
  );
};

export default Order;
