import { Link } from 'react-router-dom';
const Logo = ({ className }) => {
  return (
    <Link to={'.'} className={className}>
      AC-GO
    </Link>
  );
};

export default Logo;
