import React from 'react';
import { useContext } from '../../App';
import CardSwiper from './card/CardSwiper';
const Bestseller = () => {
  const { products } = React.useContext(useContext);
  // const [bestsellers, setBestsellers] = useState([
  //   {
  //     id: 11,
  //     img: '01',
  //     title: 'Skullcandy - Crusher anc 2 wireless headphones',
  //     price: 299.99,
  //     status: 'new',
  //     rating: 5,
  //   },
  //   {
  //     id: 12,
  //     img: '02',
  //     title: 'Beats Studio Pro',
  //     price: 349.99,
  //     status: 'new',
  //     rating: 4,
  //   },
  //   {
  //     id: 13,
  //     img: '03',
  //     title: 'Sony - WH-CH720N Wireless Noise Canceling',
  //     price: 149.99,
  //     status: 'new',
  //     rating: 3,
  //   },
  //   {
  //     id: 14,
  //     img: '04',
  //     title: 'Skullcandy - Rail True Wireless Earbuds',
  //     price: 79.99,
  //     status: 'new',
  //     rating: 2,
  //   },
  //   {
  //     id: 15,
  //     img: '05',
  //     title: 'Beats Studio Pro',
  //     price: 224.99,
  //     status: 'new',
  //     rating: 1,
  //   },
  //   {
  //     id: 16,
  //     img: '01',
  //     title: 'Skullcandy - Crusher anc 2 wireless headphones',
  //     price: 299.99,
  //     status: 'new',
  //     rating: 5,
  //   },
  //   {
  //     id: 17,
  //     img: '02',
  //     title: 'Beats Studio Pro',
  //     price: 349.99,
  //     status: 'new',
  //     rating: 4,
  //   },
  //   {
  //     id: 18,
  //     img: '03',
  //     title: 'Sony - WH-CH720N Wireless Noise Canceling',
  //     price: 149.99,
  //     status: 'new',
  //     rating: 3,
  //   },
  // ]);

  return (
    <div className="bestseller">
      <div className="container">
        <div className="bestseller__title">
          <h2 className="headline-4">Best Seller</h2>
        </div>
        <div className="bestseller__cards">
          {/* @@loop('templates/card.html', './data/bestsellers.json') */}
          {products.items.map((el) => (
            <CardSwiper key={el.id} el={el} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Bestseller;
