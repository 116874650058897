import React from 'react';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import search from '../../img/icons/search.svg';
import user from '../../img/icons/user.svg';
import cart from '../../img/icons/cart.svg';
// import chevronDown from '../../img/icons/chevron-down.svg';
import Order from '../content/Order';
import Logo from './Logo';
import ListOfMenu from './ListOfMenu';

import { useContext } from '../../App';

const Menu = () => {
  const { products } = React.useContext(useContext);
  const { orders } = products;
  const [cartOpen, setCartOpen] = useState(false);
  const [humMenu, setHumMenu] = useState(false);

  const toggleOverflowHiddenToBody = () => {
    const body = document.querySelector('body');
    body.classList.toggle('overflow-hidden');
  };
  const removeOverflowHiddenToBody = () => {
    const body = document.querySelector('body');
    body.classList.remove('overflow-hidden');
  };

  const clickOnNavLink = () => {
    setHumMenu(!humMenu);
    removeOverflowHiddenToBody();
  };

  return (
    <>
      <nav className={!humMenu ? 'nav' : 'nav nav--mobile'}>
        <div className="container">
          <div className="nav__row">
            <Logo className={'logo'} />
            <ListOfMenu
              clickOnNavLink={clickOnNavLink}
              navList={'nav__list'}
              navItem={'nav__item'}
              navLink={'nav__link'}
            />

            <div className="nav__btns">
              <button className="nav__btn">
                <img src={search} alt="" />
              </button>
              <button className="nav__btn">
                <img src={user} alt="" />
              </button>
              <button
                onClick={() => setCartOpen(!cartOpen)}
                className={`${cartOpen ? 'nav__btnCartActive' : 'nav__btn'}`}
              >
                <img src={cart} alt="" />
              </button>
              {!orders.length || (
                <span className="navBtnCount">{orders.length}</span>
              )}
              {cartOpen && (
                <div className="shop-cart">
                  <div
                    className="close-cart-window"
                    onClick={() => setCartOpen(!cartOpen)}
                  >
                    &times;
                  </div>
                  <div>
                    {orders.length > 0 ? (
                      <div className="order-wrapper">
                        {orders.map((el) => (
                          <Order key={el.id} item={el} />
                        ))}
                      </div>
                    ) : (
                      <div className="empty">
                        <h2>Корзина пуста</h2>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="humMenuActive">
              <button
                onClick={() => {
                  setHumMenu(!humMenu);
                  toggleOverflowHiddenToBody();
                }}
                type="button"
                className="nav__toggle"
              >
                <div className="menu-icon-wrapper">
                  <div
                    className={
                      !humMenu ? 'menu-icon' : 'menu-icon menu-icon--active'
                    }
                  ></div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Menu;
