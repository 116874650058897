export const carData = {
  Toyota: [
    {
      brand: 'Toyota 93-96',
      model: '4Runner',
      year: '1993-1996',
      freionBrand: 'R134a',
      freonMass: '700-800',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Auris',
      year: '2007-2012',
      freionBrand: 'R134a',
      freonMass: '450',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: '90',
    },
    {
      brand: 'Toyota',
      model: 'Auris Hybrid',
      year: '2010-',
      freionBrand: 'R134a',
      freonMass: '475',
      oilType: 'ND Oil 11 / POE',
      oilVolume: '135',
    },
    {
      brand: 'Toyota',
      model: 'Avensis (T22)',
      year: '1998-2003',
      freionBrand: 'R134a',
      freonMass: '410-470',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Avensis (T25)',
      year: '2003-2009',
      freionBrand: 'R134a',
      freonMass: '410-470',
      oilType: 'ND Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Avensis (T27)',
      year: '2009-',
      freionBrand: 'R134a',
      freonMass: '440',
      oilType: 'ND8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Avensis Verso',
      year: '2001-2006',
      freionBrand: 'R134a',
      freonMass: '470-530',
      oilType: 'ND Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Avensis Verso с системой кондиционирования сзади',
      year: '2001-2006',
      freionBrand: 'R134a',
      freonMass: '770-830',
      oilType: 'ND Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Aygo',
      year: '2005-',
      freionBrand: 'R134a',
      freonMass: '500',
      oilType: 'ZXL200PG / PAO 68 или PAG ISO 100',
      oilVolume: '80',
    },
    {
      brand: 'Toyota',
      model: 'Camry',
      year: '1994-1996',
      freionBrand: 'R134a',
      freonMass: '850',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Camry (V20)',
      year: '1996-2001',
      freionBrand: 'R134a',
      freonMass: '750-850',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Camry (V30)',
      year: '11.01-2005',
      freionBrand: 'R134a',
      freonMass: '520-580',
      oilType: 'ND Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Camry (V40)',
      year: '2006-2011',
      freionBrand: 'R134a',
      freonMass: '500-600',
      oilType: 'PAG',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Carina E',
      year: '1992-1998',
      freionBrand: 'R134a',
      freonMass: '700-800',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Celica (T20)',
      year: '1994-11.99',
      freionBrand: 'R134a',
      freonMass: '600-700',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Celica (ZZT23)',
      year: '1999-2006',
      freionBrand: 'R134a',
      freonMass: '400-460',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: '120',
    },
    {
      brand: 'Toyota',
      model: 'Corolla',
      year: '1994-1997',
      freionBrand: 'R134a',
      freonMass: '650-750',
      oilType: 'Dens Oil 9 / PAO 68 или PAG ISO 100',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Corolla',
      year: '1997-2000',
      freionBrand: 'R134a',
      freonMass: '600-700',
      oilType: 'Dens Oil 9 / PAO 68 или PAG ISO 100',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Corolla 1,9D – изготовленные в Японии',
      year: '2000-2002',
      freionBrand: 'R134a',
      freonMass: '600-700',
      oilType: 'Dens Oil 9 / PAO 68 или PAG ISO 100',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Corolla 1,9D – изготовленные в Великобритании',
      year: '2000-2002',
      freionBrand: 'R134a',
      freonMass: '450-490',
      oilType: 'Dens Oil 9 / PAO 68 или PAG ISO 100',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Corolla (E12U)',
      year: '2002-2008',
      freionBrand: 'R134a',
      freonMass: '425-475',
      oilType: 'ND Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Corolla',
      year: '2008-',
      freionBrand: 'R134a',
      freonMass: '440',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'GT 86',
      year: '03.2012-',
      freionBrand: 'R134a',
      freonMass: '390',
      oilType: 'ND8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'GT 86',
      year: '03.2012-',
      freionBrand: 'R1234yf',
      freonMass: '350',
      oilType: 'ND12 / PAO 68 или PAG ISO 46 YF',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Harrier (XU10)',
      year: '1997-2003',
      freionBrand: 'R134a',
      freonMass: '600-700',
      oilType: 'Denso ND Oil 11 / ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Highlander (U20)',
      year: '2001-2007',
      freionBrand: 'R134a',
      freonMass: '600-700',
      oilType: 'Denso ND Oil 8 / ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Highlander (U40)',
      year: '2007-2013',
      freionBrand: 'R134a',
      freonMass: '550-650',
      oilType: 'Denso ND Oil 8 / ISO 46',
      oilVolume: '65-95',
    },
    {
      brand: 'Toyota',
      model: 'Highlander (U40) с системой кондиционирования сзади',
      year: '2007-2013',
      freionBrand: 'R134a',
      freonMass: '720-820',
      oilType: 'Denso ND Oil 8 / ISO 46',
      oilVolume: '65-95',
    },
    {
      brand: 'Toyota',
      model: 'Hi-Lux 93-96',
      year: '1993-1996',
      freionBrand: 'R134a',
      freonMass: '700-800',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Hi-Lux 97-05',
      year: '1997-2005',
      freionBrand: 'R134a',
      freonMass: '500-600',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Hi-Lux 2005 - ',
      year: '08.2005-',
      freionBrand: 'R134a',
      freonMass: '450',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: '80',
    },
    {
      brand: 'Toyota',
      model: 'iQ',
      year: '2008-',
      freionBrand: 'R134a',
      freonMass: '370',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Landcruiser 3,4',
      year: '1996-2003',
      freionBrand: 'R134a',
      freonMass: '650-750',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Landcruiser 3,4 с системой кондиционирования сзади',
      year: '1996-2003',
      freionBrand: 'R134a',
      freonMass: '900-1000',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Landcruiser 4,5',
      year: '1995-1998',
      freionBrand: 'R134a',
      freonMass: '750',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Landcruiser Amazon 4,7/4,2 Turbo D',
      year: '1998-2000',
      freionBrand: 'R134a',
      freonMass: '750',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Landcruiser 3,0 Turbo D',
      year: '1993-1996',
      freionBrand: 'R134a',
      freonMass: '850-900',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Landcruiser 3,0 Turbo D с системой кондиционирования сзади',
      year: '1993-1996',
      freionBrand: 'R134a',
      freonMass: '1550-1650',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Toyota',
      model: 'Landcruiser Colorado/Prado',
      year: '1997-1999',
      freionBrand: 'R134a',
      freonMass: '650-750',
      oilType: 'Dens Oil 8 / PAO 68 или PAG ISO 46',
      oilVolume: 'н.д.',
    },
  ],
  Honda: [
    {
      brand: 'Honda',
      model: 'Accord 93-98',
      year: '1993-98',
      freionBrand: 'R134a',
      freonMass: '700-750',
      oilType: 'Dens Oil 8',
      oilVolume: '160',
    },

    {
      brand: 'Honda',
      model: 'Accord diesel RHD',
      year: '1996-98',
      freionBrand: 'R134a',
      freonMass: '700',
      oilType: 'SP10',
      oilVolume: '135',
    },
    {
      brand: 'Honda',
      model: 'Accord diesel LHD',
      year: '1996-98',
      freionBrand: 'R134a',
      freonMass: '650',
      oilType: 'SP10',
      oilVolume: '135',
    },
    {
      brand: 'Honda',
      model: 'Accord 98-03',
      year: '1998-03',
      freionBrand: 'R134a',
      freonMass: '500-550',
      oilType: 'Dens Oil 8',
      oilVolume: '160',
    },
    {
      brand: 'Honda',
      model: 'Accord Coupe',
      year: '1998-03',
      freionBrand: 'R134a',
      freonMass: '600-650',
      oilType: 'Dens Oil 8',
      oilVolume: '160-175',
    },
    {
      brand: 'Honda',
      model: 'Accord VII 2,0i/2,4i',
      year: '2003-08',
      freionBrand: 'R134a',
      freonMass: '500-550',
      oilType: 'SP10',
      oilVolume: '130',
    },
    {
      brand: 'Honda',
      model: 'Accord VII 2,2 Diesel',
      year: '2003-08',
      freionBrand: 'R134a',
      freonMass: '450-500',
      oilType: 'Dens Oil 8',
      oilVolume: '160',
    },
    {
      brand: 'Honda',
      model: 'Accord VIII 2,0i/2,4i',
      year: '07.2008-',
      freionBrand: 'R134a',
      freonMass: '450 - 500',
      oilType: 'SP10',
      oilVolume: '185',
    },
    {
      brand: 'Honda',
      model: 'Accord VIII 2.2D',
      year: '2008-12',
      freionBrand: 'R134a',
      freonMass: '450-500',
      oilType: 'Dens Oil 8',
      oilVolume: '180',
    },
    {
      brand: 'Honda',
      model: 'Civic 94-95',
      year: '1994-95',
      freionBrand: 'R134a',
      freonMass: '500-550',
      oilType: 'Dens Oil 8',
      oilVolume: '120',
    },

    {
      brand: 'Honda',
      model: 'Civic 2/3/4-door',
      year: '1996-00',
      freionBrand: 'R134a',
      freonMass: '600-650',
      oilType: 'Dens Oil 8',
      oilVolume: '140',
    },

    {
      brand: 'Honda',
      model: 'Civic 5-door 95-97',
      year: '1995-97',
      freionBrand: 'R134a',
      freonMass: '500-550',
      oilType: 'SP10',
      oilVolume: '120',
    },
    {
      brand: 'Honda',
      model: 'Civic 5-door 97-00',
      year: '1997-00',
      freionBrand: 'R134a',
      freonMass: '500-550',
      oilType: 'Dens Oil 8',
      oilVolume: '120',
    },
    {
      brand: 'Honda',
      model: 'Civic 5-door diesel RHD',
      year: '1997-00',
      freionBrand: 'R134a',
      freonMass: '560',
      oilType: 'SP10',
      oilVolume: '135',
    },
    {
      brand: 'Honda',
      model: 'Civic 5-door diesel LHD',
      year: '1997-00',
      freionBrand: 'R134a',
      freonMass: '640',
      oilType: 'SP10 OIL или PAG ISO 46',
      oilVolume: '135',
    },
    {
      brand: 'Honda',
      model: 'Civic/Civic Coupe EU/EP/EM 1.4i/1.6i/2.0i',
      type: 'EU/EP/EM 1.4i/1.6i/2.0i',
      year: '2001-06',
      freionBrand: 'R134a',
      freonMass: '500-550',
      oilType: 'SP10 OIL или PAG ISO 46',
      oilVolume: '130-150',
    },
    {
      brand: 'Honda',
      model: 'Civic 1.7D CTDi',
      year: '2002-06',
      freionBrand: 'R134a',
      freonMass: '500-550',
      oilType: 'PAG OIL или PAG ISO 46',
      oilVolume: '145',
    },
    {
      brand: 'Honda',
      model: 'Civic VIII 1.4i/1.8i',
      year: '2006-',
      freionBrand: 'R134a',
      freonMass: '450-480',
      oilType: 'SP10 OIL или PAG ISO 46',
      oilVolume: '80',
    },
    {
      brand: 'Honda',
      model: 'Civic VIII 2.2D i-CTDi',
      year: '2006-',
      freionBrand: 'R134a',
      freonMass: '450-480',
      oilType: 'SP10 OIL или PAG ISO 46',
      oilVolume: '85',
    },
    {
      brand: 'Honda',
      model: 'Civic IX 1.4i-VTEC/1.8i-VTEC/2.2Di-DTEC LHD',
      type: '1.4i-VTEC/1.8i-VTEC/2.2Di-DTEC LHD',
      year: '2012-',
      freionBrand: 'R134a',
      freonMass: '380',
      oilType: 'ND8 OIL или PAG ISO 46',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Honda',
      model: 'Civic hybrid IMALHD',
      year: '2006-10',
      freionBrand: 'R134a',
      freonMass: '475',
      oilType: 'SE-10Y',
      oilVolume: 'н.д.',
    },
    {
      brand: 'Honda',
      model: 'Concerto',
      year: '1993-95',
      freionBrand: 'R134a',
      freonMass: '750-800',
      oilType: 'SP10 OIL или PAG ISO 46',
      oilVolume: '120',
    },

    {
      brand: 'Honda',
      model: 'CRV RHD 97-02',
      year: '1997-02',
      freionBrand: 'R134a',
      freonMass: '600-650',
      oilType: 'SP10 OIL или PAG ISO 46',
      oilVolume: '130-150',
    },
    {
      brand: 'Honda',
      model: 'CRV (RD) 02-07',
      year: '2002-07',
      freionBrand: 'R134a',
      freonMass: '480-530',
      oilType: 'SP10 OIL или PAG ISO 46',
      oilVolume: '130-150',
    },
    {
      brand: 'Honda',
      model: 'CRV 2.2D i-CTDi (RD) 04-07',
      year: '2004-07',
      freionBrand: 'R134a',
      freonMass: '450-500',
      oilType: 'Dens Oil 8 или PAG ISO 46',
      oilVolume: '160',
    },
    {
      brand: 'Honda',
      model: 'CRV 2.0i/2.2D-CTDi/2.4i',
      year: '2007-',
      freionBrand: 'R134a',
      freonMass: '465',
      oilType: 'SP10 OIL или PAG ISO 46',
      oilVolume: '185',
    },
    {
      brand: 'Honda',
      model: 'CR-Z 1.5 Hybrid IMA',
      year: '2010-',
      freionBrand: 'R134a',
      freonMass: '475',
      oilType: 'SP10 OIL или PAG ISO 46',
      oilVolume: '165',
    },
    {
      brand: 'Honda',
      model: 'FR-V',
      compressor: 'Sanden',
      year: '2005-07',
      freionBrand: 'R134a',
      freonMass: '550',
      oilType: 'PAG OIL или PAG ISO 46',
      oilVolume: '130',
    },
    {
      brand: 'Honda',
      model: 'FR-V',
      year: '2007-',
      freionBrand: 'R134a',
      freonMass: '550',
      oilType: 'SP10 OIL или PAG ISO 46',
      oilVolume: '170',
    },
    {
      brand: 'Honda',
      model: 'HR-V 1.6i',
      year: '1999-06',
      freionBrand: 'R134a',
      freonMass: '550-650',
      oilType: 'SP10 OIL или PAG ISO 46',
      oilVolume: '130-150',
    },
    {
      brand: 'Honda',
      model: 'Insight 1.3i DSi-VTEC (IMA/Hybrid) LHD',
      year: '2006-',
      freionBrand: 'R134a',
      freonMass: '425',
      oilType: 'SE-10Y',
      oilVolume: '140',
    },
    {
      brand: 'Honda',
      model: 'Insight 1.3i DSi-VTEC (IMA/Hybrid) RHD',
      year: '2006-',
      freionBrand: 'R134a',
      freonMass: '475',
      oilType: 'SE-10Y',
      oilVolume: '140',
    },
    {
      brand: 'Honda',
      model: 'Jazz 01-08',
      year: '2001-08',
      freionBrand: 'R134a',
      freonMass: '400-450',
      oilType: 'Dens Oil 8 или PAG ISO 46',
      oilVolume: '85',
    },
    {
      brand: 'Honda',
      model: 'Jazz 2008-',
      year: '2008-',
      freionBrand: 'R134a',
      freonMass: '400',
      oilType: 'PAG OIL или PAG ISO 46',
      oilVolume: '85',
    },
    {
      brand: 'Honda',
      model: 'Legend IV (KB) 3.5i/3.7i',
      year: '05.2006-',
      freionBrand: 'R134a',
      freonMass: '475',
      oilType: 'ND8 OIL или PAG ISO 46',
      oilVolume: '120',
    },

    {
      brand: 'Honda',
      model: 'Prelude',
      year: '1997-01',
      freionBrand: 'R134a',
      freonMass: '700-750',
      oilType: 'SP10 OIL или PAG ISO 46',
      oilVolume: '130-150',
    },
    {
      brand: 'Honda',
      model: 'S2000',
      year: '1999-04',
      freionBrand: 'R134a',
      freonMass: '600-650',
      oilType: 'SP10 OIL или PAG ISO 46',
      oilVolume: '130-150',
    },
    {
      brand: 'Honda',
      model: 'Shuttle 2.2i/2.3i',
      year: '1995-01',
      freionBrand: 'R134a',
      freonMass: '600-650',
      oilType: 'Dens Oil 8 или PAG ISO 46',
      oilVolume: '160',
    },
    {
      brand: 'Honda',
      model: 'Shuttle 2.2i/2.3i два контура',
      year: '1995-01',
      freionBrand: 'R134a',
      freonMass: '800-850',
      oilType: 'Dens Oil 8 или PAG ISO 46',
      oilVolume: '160',
    },
    {
      brand: 'Honda',
      model: 'Stream 1.7i',
      year: '2001-06',
      freionBrand: 'R134a',
      freonMass: '500-550',
      oilType: 'SP10 OIL или PAG ISO 46',
      oilVolume: '130-150',
    },
    {
      brand: 'Honda',
      model: 'Stream 2.0i',
      year: '2001-06',
      freionBrand: 'R134a',
      freonMass: '500-550',
      oilType: 'SP10 OIL или PAG ISO 46',
      oilVolume: '160-175',
    },
    {
      brand: 'Honda',
      model: 'Stream 2.0i два контура',
      year: '2001-06',
      freionBrand: 'R134a',
      freonMass: '700-750',
      oilType: 'SP10 OIL или PAG ISO 46',
      oilVolume: '160-175',
    },
  ],
  Audi: [
    {
      model: 'A1 1,4 TSI',
      year: '2010-15',
      freionBrand: 'R134a',
      freonMass: '485-515',
      oilType: 'ISO 46',
      oilVolume: '1',
    },
    {
      model: 'A3 II / III (8P/8V)',
      year: '2003-16',
      freionBrand: 'R134a',
      freonMass: '500-550',
      oilType: 'ISO 46',
      oilVolume: '3',
    },
    {
      model: 'A4 III (B7) / Avant',
      year: '2004-09',
      freionBrand: 'R134a',
      freonMass: '480-530',
      oilType: 'ISO 46',
      oilVolume: '170-190',
    },
    {
      model: 'A4 III (B7) 1,9 TDI / Avant',
      year: '2004-09',
      freionBrand: 'R134a',
      freonMass: '580-600',
      oilType: 'PAG 81 / ISO 46',
      oilVolume: '180',
    },
    {
      model: 'A4 IV (B8) / A5 Coupe',
      year: '2007-15',
      freionBrand: 'R134a',
      freonMass: '550-590',
      oilType: 'ISO 46',
      oilVolume: '5',
    },
    {
      model: 'A4 V (B9)',
      year: '2015-18',
      freionBrand: 'R134a / R1234yf',
      freonMass: '575-605 / 500-550',
      oilType: '#10 20# G 052 300 / G 055 535',
      oilVolume: '110',
    },
    {
      model: 'A6 III (C6) / Avant',
      year: '2004-11',
      freionBrand: 'R134a',
      freonMass: '510-550',
      oilType: 'ISO 46',
      oilVolume: '120-140',
    },
    {
      model: 'A6 IV (C7)',
      year: '2011-18',
      freionBrand: 'R134a / R1234yf',
      freonMass: '550-590 / 515-545',
      oilType: 'ISO 46',
      oilVolume: '110-130 / 100-120',
    },
    {
      model: 'A7 3,0 TDI',
      year: '2011-18',
      freionBrand: 'R134a',
      freonMass: '550-590',
      oilType: 'Denso = G052 300 A2 / ISO 46',
      oilVolume: '110-130',
    },
    {
      model: 'A8 II (D3)',
      year: '2003-10',
      freionBrand: 'R134a',
      freonMass: '600-640',
      oilType: 'ISO 46',
      oilVolume: '140-160',
    },
    {
      model: 'A8 III (D4)',
      year: '2010-17',
      freionBrand: 'R134a',
      freonMass: '760-800 / 910-950',
      oilType: '#7# G 052 300 / #8# G 052 300',
      oilVolume: '120-140',
    },
    {
      model: 'TT I (8N)',
      year: '1998-06',
      freionBrand: 'R134a',
      freonMass: '638-662 / 750-800',
      oilType: '#12# / #13#',
      oilVolume: '120-150',
    },
    {
      model: 'TT II (8J)',
      year: '2006-14',
      freionBrand: 'R134a',
      freonMass: '500-550',
      oilType: 'G052 300 A2 / G052 154 A2',
      oilVolume: '80-100 / 100-120',
    },
    {
      model: 'TT III (8S)',
      year: '2014-18',
      freionBrand: 'R134a / R1234yf',
      freonMass: '485-515 / 445-475',
      oilType: '#10 20# 104 / #10 20# 105',
      oilVolume: '83',
    },
    {
      model: 'Q3',
      year: '2011-18',
      freionBrand: 'R134a',
      freonMass: '500-540',
      oilType: 'ISO 46',
      oilVolume: '4',
    },
    {
      model: 'Q5',
      year: '2008-18',
      freionBrand: 'R134a',
      freonMass: '580-620',
      oilType: 'ISO 46',
      oilVolume: '140-160',
    },
    {
      model: 'Q7',
      year: '2006-15 / 2015-18',
      freionBrand: 'R134a / R1234yf',
      freonMass: '470-490 / 1050-1100 / 700-750 / 950-1000',
      oilType:
        '#7# G052 300 A2 / #8# G052 300 / #7 20# G052 300 / #8 20# G052 300 / #7 20# G 055 535 / #8 20# G 055 535',
      oilVolume: '130-160 / 230-260 / 130-160 / 130-155 / 100-120 / 130-155',
    },
  ],
};
