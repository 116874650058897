import { useState } from 'react';
import { Link } from 'react-router-dom';
import FeedbackForm from '../myForm/FeedbackForm';

const Hero = () => {
  const [overlay, setOverlay] = useState(false);
  const overlayHidden = overlay ? 'overlay' : 'overlay hidden';
  const modalHidden = overlay ? 'modal-window' : 'modal-window hidden';

  const toogleOverlay = () => setOverlay(!overlay);

  return (
    <div className="hero">
      <div className="hero__container">
        <div className="hero__content">
          <h1 className="hero__title">
            Заправка автокондиционеров
            <span> С выездом</span>
          </h1>
          <div className="hero__desc">
            <p>Когда заправка кондиционера максимально удобна</p>
          </div>

          <div className="hero__link">
            <Link onClick={toogleOverlay} to="#" className="btn">
              Оставить заявку
            </Link>
          </div>
        </div>
      </div>
      <div className={overlayHidden} onClick={toogleOverlay}></div>
      <div className={modalHidden}>
        <button className="close-modal-window" onClick={toogleOverlay}>
          &times;
        </button>
        <h2>Оставьте заявку</h2>
        <FeedbackForm />
      </div>
    </div>
  );
};

export default Hero;
