import { IoCheckmarkCircle } from 'react-icons/io5';
const Services = () => {
  return (
    <section className="services">
      <div className="container">
        <div className="services__title">
          <h2 className="headline-4">Услуги</h2>
        </div>
        <div className="type__services">
          <div className="type__services-text">
            <IoCheckmarkCircle className="check-icon" />
            Выезд к вам
          </div>
          <div className="type__services-text">
            <IoCheckmarkCircle className="check-icon" />
            Заправка хладагентом
          </div>
          <div className="type__services-text">
            <IoCheckmarkCircle className="check-icon" />
            Добавление красителя
          </div>
          <div className="type__services-text">
            <IoCheckmarkCircle className="check-icon" />
            Опрессовка системы при помощи азота
          </div>
          <div className="type__services-text">
            <IoCheckmarkCircle className="check-icon" />
            Замена ниппеля
          </div>
          <div className="type__services-text">
            <IoCheckmarkCircle className="check-icon" />
            Подключение к системе с фреоном R1234yf
          </div>
          <div className="type__services-text">
            <IoCheckmarkCircle className="check-icon" />
            Замена резиновых колечек
          </div>
          <div className="type__services-text">
            <IoCheckmarkCircle className="check-icon" />
            Дианостика автокондиционера
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
