import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import MainLayout from './layouts/MainLayout';
import Home from './components/Home';
import Shop from './components/menu/Shop';
import { useState } from 'react';

export const useContext = React.createContext();

function App() {
  const [products, setProducts] = useState({
    orders: [],
    items: [
      {
        id: 1,
        img: '01',
        title: 'Колпачки для портов R134a R12',
        price: 300,
        status: 'new',
        rating: 5,
      },
      {
        id: 2,
        img: '02',
        title: 'Beats Studio Pro',
        price: 349.99,
        status: 'new',
        rating: 4,
      },
      {
        id: 3,
        img: '03',
        title: 'Sony - WH-CH720N Wireless Noise Canceling',
        price: 149.99,
        status: 'new',
        rating: 3,
      },
      {
        id: 4,
        img: '04',
        title: 'Skullcandy - Rail True Wireless Earbuds',
        price: 79.99,
        status: 'new',
        rating: 2,
      },
      {
        id: 5,
        img: '05',
        title: 'Beats Studio Pro',
        price: 224.99,
        status: 'new',
        rating: 1,
      },
      {
        id: 6,
        img: '01',
        title: 'Skullcandy - Crusher anc 2 wireless headphones',
        price: 299.99,
        status: 'new',
        rating: 5,
      },
      {
        id: 7,
        img: '02',
        title: 'Beats Studio Pro',
        price: 349.99,
        status: 'new',
        rating: 4,
      },
      {
        id: 8,
        img: '03',
        title: 'Sony - WH-CH720N Wireless Noise Canceling',
        price: 149.99,
        status: 'new',
        rating: 3,
      },
      {
        id: 9,
        img: '04',
        title: 'Skullcandy - Rail True Wireless Earbuds',
        price: 79.99,
        status: 'new',
        rating: 2,
      },
      {
        id: 10,
        img: '05',
        title: 'Beats Studio Pro',
        price: 224.99,
        status: 'new',
        rating: 1,
      },
    ],
  });

  console.log('App обновилась');

  const addToOrder = (item) => {
    let isInArray = false;

    products.orders.forEach((el) => {
      if (el.id === item.id) isInArray = true;
    });

    if (!isInArray)
      setProducts((prevState) => ({
        ...prevState,
        orders: [...prevState.orders, item],
      }));
  };

  const deleteOrder = (order) => {
    setProducts((prev) => ({
      ...prev,
      orders: prev.orders.filter((p) => p.id !== order.id),
    }));
  };

  return (
    <BrowserRouter>
      <useContext.Provider value={{ products, deleteOrder, addToOrder }}>
        <div className="App">
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Home />} />
              <Route path="shop" element={<Shop />} />
              <Route />
            </Route>
          </Routes>
        </div>
      </useContext.Provider>
    </BrowserRouter>
  );
}

export default App;
