import React, { useState } from 'react';
import axios from 'axios';

const FeedbackForm = () => {
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  const validate = () => {
    let formErrors = {};
    if (!phone) {
      formErrors.phone = 'Телефон обязателен';
    } else if (!/^\d{11}$/.test(phone)) {
      formErrors.phone = 'Телефон должен содержать 11 цифр';
    }
    if (!message) {
      formErrors.message = 'Сообщение обязательно';
    }
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validate();
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await axios.post('http://localhost:5000/send', {
          phone,
          message,
        });
        alert('Форма отправлена успешно');
        setPhone('');
        setMessage('');
        setErrors({});
      } catch (error) {
        console.error('Ошибка отправки формы', error);
        alert('Произошла ошибка при отправке формы');
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <div className="contact-form">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            Телефон:
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </label>
          {errors.phone && <div style={{ color: 'red' }}>{errors.phone}</div>}
        </div>
        <div className="form-group">
          <label>
            Сообщение:
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </label>
          {errors.message && (
            <div style={{ color: 'red' }}>{errors.message}</div>
          )}
        </div>
        <div className="form-group">
          <button type="submit">Отправить</button>
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;
