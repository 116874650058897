import Promotion from './content/Promotion';
import Values from './content/Values';
import Newsletter from './content/Newsletter';
import Services from './content/Services';
import CarDataComponent from './content/selectCar/CarDataComponent';
import Hero from '../components/content/Hero';
import FeedbackForm from './myForm/FeedbackForm';

const Home = () => {
  return (
    <div>
      <Hero />
      <Services />
      <CarDataComponent />
      <Values />

      <Newsletter />
    </div>
  );
};

export default Home;
