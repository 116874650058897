import { NavLink } from 'react-router-dom';

const ListOfMenu = ({ clickOnNavLink, navList, navItem, navLink }) => {
  return (
    <ul className={navList}>
      <li className={navItem}>
        <NavLink onClick={clickOnNavLink} className={navLink} to="." end>
          Главная
        </NavLink>
      </li>
      <li className={navItem}>
        <NavLink onClick={clickOnNavLink} className={navLink} to="shop">
          Магазин
        </NavLink>
      </li>

      <li className={navItem}>
        <NavLink onClick={clickOnNavLink} className={navLink} to="home">
          Контакты
        </NavLink>
      </li>
    </ul>
  );
};

export default ListOfMenu;
